import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState, useEffect } from "react";
import { connectEbay, getActiveChannel } from "../../api/Account";

const Channels = (props) => {
    const [loading, setLoading] = useState(true);
    const [channel, setChannel] = useState({});
    useEffect(() => {
        async function fetchData() {
            setChannel(await getActiveChannel());
            setLoading(false);
        }
        fetchData();
    }, []);

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            You haven't completed the other steps.
        </Tooltip>
    );

    const handleEbay = async () => {
        const { url } = await connectEbay(channel._id);
        window.location.href = url;
    };

    if (loading) return <span>Loading</span>;
    return (
        <>
            <Header history={props.history} profile={props.profile} />
            <Container className="mt-5">
                <div className="channels-header">
                    <span>Channel Name</span>
                    <input
                        type="text"
                        placeholder="Unnamed Channel"
                        value={channel.name}
                        className="form-control channels-name"
                    />
                </div>
                <div className="channels-steps">
                    <Row>
                        <Col sm={6}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Connect to Marketplace
                                        <span className="channels-card-step">
                                            {channel.marketplace == null ? (
                                                "STEP 1"
                                            ) : (
                                                <img
                                                    src="/images/check-green.svg"
                                                    width={30}
                                                    alt="Step 1 Complete"
                                                />
                                            )}
                                        </span>
                                    </Card.Title>
                                    <Card.Text>
                                        We require a connection to the
                                        marketplace so we can export the
                                        financial data.
                                    </Card.Text>
                                    <div className="channels-accounting">
                                        <ul>
                                            <li>
                                                <img
                                                    src="/images/logo-amazon.svg"
                                                    alt="Amazon Logo"
                                                    width={80}
                                                />
                                                {channel.marketplace &&
                                                channel.marketplace.platform ===
                                                    "amazon" ? (
                                                    <Button
                                                        onClick={handleEbay}
                                                        variant="danger"
                                                    >
                                                        Disconnect
                                                    </Button>
                                                ) : channel.marketplace ==
                                                  null ? (
                                                    <Button
                                                        onClick={handleEbay}
                                                    >
                                                        Connect to Amazon
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        disabled
                                                        style={{
                                                            pointerEvents:
                                                                "none",
                                                        }}
                                                    >
                                                        Connect to Amazon
                                                    </Button>
                                                )}
                                            </li>
                                            <li>
                                                <img
                                                    src="/images/logo-ebay.svg"
                                                    alt="eBay Logo"
                                                    width={80}
                                                />
                                                {channel.marketplace &&
                                                channel.marketplace.platform ===
                                                    "ebay" ? (
                                                    <Button
                                                        onClick={handleEbay}
                                                        variant="danger"
                                                    >
                                                        Disconnect
                                                    </Button>
                                                ) : channel.marketplace ==
                                                  null ? (
                                                    <Button
                                                        onClick={handleEbay}
                                                    >
                                                        Connect to eBay
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        disabled
                                                        style={{
                                                            pointerEvents:
                                                                "none",
                                                        }}
                                                    >
                                                        Connect to eBay
                                                    </Button>
                                                )}
                                            </li>
                                            <li>
                                                <img
                                                    src="/images/logo-etsy.svg"
                                                    alt="Etsy Logo"
                                                    width={80}
                                                />
                                                {channel.marketplace &&
                                                channel.marketplace.platform ===
                                                    "etsy" ? (
                                                    <Button
                                                        onClick={handleEbay}
                                                        variant="danger"
                                                    >
                                                        Disconnect
                                                    </Button>
                                                ) : channel.marketplace ==
                                                  null ? (
                                                    <Button
                                                        onClick={handleEbay}
                                                    >
                                                        Connect to Etsy
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        disabled
                                                        style={{
                                                            pointerEvents:
                                                                "none",
                                                        }}
                                                    >
                                                        Connect to Etsy
                                                    </Button>
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                    <Card.Text>
                                        You want more support for different
                                        marketplaces?{" "}
                                        <a href="/">Contact us!</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Connect to Accounting Platform
                                        <span className="channels-card-step">
                                            STEP 2
                                        </span>
                                    </Card.Title>
                                    <Card.Text>
                                        Establish a connection to your
                                        accounting platform, so you can post the
                                        financial data.
                                    </Card.Text>
                                    <div className="channels-accounting">
                                        <ul>
                                            <li>
                                                <img
                                                    src="/images/logo-quickbooks.svg"
                                                    alt="QuickBooks Logo"
                                                    width={130}
                                                />
                                                <Button>
                                                    Connect to QuickBooks
                                                </Button>
                                            </li>
                                            <li>
                                                <img
                                                    src="/images/logo-xero.svg"
                                                    alt="Xero Logo"
                                                    width={40}
                                                />
                                                <Button>Connect to Xero</Button>
                                            </li>
                                        </ul>
                                    </div>
                                    <Card.Text>
                                        Are we missing a platform?{" "}
                                        <a href="/">Contact us!</a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Configure Taxes
                                        <span className="channels-card-step">
                                            STEP 3
                                        </span>
                                    </Card.Title>
                                    <Card.Text>
                                        Make sure to configure how you want
                                        SyncBooks to manage your taxes for the
                                        marketplace.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>
                                        Sync your Settlements
                                        <span className="channels-card-step">
                                            STEP 4
                                        </span>
                                    </Card.Title>
                                    <Card.Text>
                                        Once you have completed all of the other
                                        steps, you can now sync your settlements
                                        with your preferred accounting platform.
                                    </Card.Text>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <span className="d-inline-block">
                                            <Button
                                                disabled
                                                style={{
                                                    pointerEvents: "none",
                                                }}
                                            >
                                                View Settlements
                                            </Button>
                                        </span>
                                    </OverlayTrigger>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default Channels;
