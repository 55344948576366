import { Route, Redirect } from "react-router-dom";
import { useState, useEffect } from "react";
import { getProfile } from "../api/Account";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProfile()
            .then((res) => {
                setProfile(res.data.profile);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    return (
        <Route
            {...rest}
            render={(props) =>
                profile != null ? (
                    <Component {...props} profile={profile} />
                ) : loading ? (
                    <></>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
