import { useState, useEffect } from "react";
import Header from "../../components/Header";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Footer from "../../components/Footer";
import { getSettlements } from "../../api/Account";

const Dashboard = (props) => {
    const [settlements, setSettlements] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setSettlements(await getSettlements());
        }
        fetchData();
    }, []);

    return (
        <>
            <Header history={props.history} profile={props.profile} />
            <Container className="mt-5">
                <div className="settlements">
                    <div className="settlements-top">
                        <span className="settlements-title">Settlements</span>
                        <input
                            type="text"
                            placeholder="Search for settlement..."
                            className="form-control settlements-search"
                        />
                    </div>
                    <Table id="settlements-table">
                        <thead>
                            <tr>
                                <th>Marketplace</th>
                                <th>Channel</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Send to Xero</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {settlements != null && (
                            <tbody>
                                {settlements.map(function (settlement, index) {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <svg
                                                    version="1.1"
                                                    className="ebay"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 512.019 512.019"
                                                >
                                                    <path
                                                        fill="#F44336"
                                                        d="M64,320.009c-35.346,0-64-28.654-64-64s28.654-64,64-64s64,28.654,64,64
        c0,5.891-4.776,10.667-10.667,10.667H22.677c4.865,18.842,21.863,32.005,41.323,32c27.093,0,34.624-4.096,44.928-17.259
        c3.635-4.636,10.34-5.448,14.976-1.813c4.636,3.635,5.448,10.34,1.813,14.976l0,0C111.391,313.767,87.684,323.535,64,320.009z
         M22.677,245.343h82.645c-5.891-22.816-29.162-36.537-51.978-30.646c-15.029,3.88-26.766,15.617-30.646,30.646H22.677z"
                                                    />
                                                    <g>
                                                        <path
                                                            fill="#2196F3"
                                                            d="M192,320.009c-35.346,0-64-28.654-64-64s28.654-64,64-64s64,28.654,64,64
            S227.346,320.009,192,320.009z M192,213.343c-23.564,0-42.667,19.103-42.667,42.667s19.103,42.667,42.667,42.667
            s42.667-19.103,42.667-42.667S215.564,213.343,192,213.343z"
                                                        />
                                                        <path
                                                            fill="#2196F3"
                                                            d="M138.667,320.009c-5.891,0-10.667-4.776-10.667-10.667V160.009c0-5.891,4.776-10.667,10.667-10.667
            s10.667,4.776,10.667,10.667v149.333C149.333,315.234,144.558,320.009,138.667,320.009z"
                                                        />
                                                    </g>
                                                    <g>
                                                        <path
                                                            fill="#FFC107"
                                                            d="M373.333,320.009c-5.891,0-10.667-4.776-10.667-10.667v-64c0-21.099-29.867-32-42.667-32
            c-13.28-1.865-26.394,4.361-33.344,15.829c-2.907,5.124-9.418,6.92-14.541,4.013c-5.045-2.863-6.877-9.232-4.125-14.338
            c10.657-18.284,30.934-28.747,52.011-26.837c22.976,0,64,17.813,64,53.333v64C384,315.234,379.224,320.009,373.333,320.009z"
                                                        />
                                                        <path
                                                            fill="#FFC107"
                                                            d="M309.333,320.009c-46.4,0-53.333-26.731-53.333-42.667c-0.04-7.061,2.867-13.819,8.021-18.645
            c16.192-15.552,53.333-15.445,93.355-13.824c6.272,0.256,11.755,0.469,15.957,0.469c5.891-0.007,10.672,4.764,10.679,10.655
            c0.002,1.885-0.495,3.737-1.441,5.367c1.523,2.634,1.849,5.793,0.896,8.683C371.813,300.706,342.126,320.714,309.333,320.009z
             M329.045,265.503c-17.207-1.857-34.591,1.097-50.219,8.533c-0.991,0.805-1.544,2.031-1.493,3.307c0,10.539,3.797,21.333,32,21.333
            c22.409,0.733,43.096-11.965,52.587-32.277l-5.397-0.235C349.269,265.908,339.371,265.503,329.045,265.503z"
                                                        />
                                                    </g>
                                                    <g>
                                                        <path
                                                            fill="#4CAF50"
                                                            d="M437.333,309.343c-3.568-0.002-6.898-1.787-8.875-4.757l-64-96
            c-3.27-4.901-1.947-11.525,2.955-14.795s11.525-1.947,14.795,2.955l64,96c3.264,4.904,1.934,11.526-2.971,14.79
            c-1.749,1.164-3.803,1.785-5.904,1.786V309.343z"
                                                        />
                                                        <path
                                                            fill="#4CAF50"
                                                            d="M416,362.676c-5.891,0.01-10.675-4.758-10.685-10.649c-0.003-1.862,0.481-3.692,1.405-5.309
            l85.333-149.333c2.946-5.119,9.483-6.882,14.603-3.936c5.119,2.946,6.882,9.483,3.936,14.603l-85.333,149.333
            C423.339,360.676,419.81,362.692,416,362.676z"
                                                        />
                                                    </g>
                                                </svg>
                                            </td>
                                            <td>{settlement.name}</td>
                                            <td>{settlement.amount}</td>
                                            <td>{settlement.date}</td>
                                            <td>{settlement.status}</td>
                                            <td>
                                                <Button size="sm">Send</Button>
                                            </td>
                                            <td>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    className="action"
                                                >
                                                    <path d="M12.015 7c4.751 0 8.063 3.012 9.504 4.636-1.401 1.837-4.713 5.364-9.504 5.364-4.42 0-7.93-3.536-9.478-5.407 1.493-1.647 4.817-4.593 9.478-4.593zm0-2c-7.569 0-12.015 6.551-12.015 6.551s4.835 7.449 12.015 7.449c7.733 0 11.985-7.449 11.985-7.449s-4.291-6.551-11.985-6.551zm-.015 7l-3.36-2.171c-.405.625-.64 1.371-.64 2.171 0 2.209 1.791 4 4 4s4-1.791 4-4-1.791-4-4-4c-.742 0-1.438.202-2.033.554l2.033 3.446z" />
                                                </svg>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    className="action"
                                                >
                                                    <path d="M9 12l-4.463 4.969-4.537-4.969h3c0-4.97 4.03-9 9-9 2.395 0 4.565.942 6.179 2.468l-2.004 2.231c-1.081-1.05-2.553-1.699-4.175-1.699-3.309 0-6 2.691-6 6h3zm10.463-4.969l-4.463 4.969h3c0 3.309-2.691 6-6 6-1.623 0-3.094-.65-4.175-1.699l-2.004 2.231c1.613 1.526 3.784 2.468 6.179 2.468 4.97 0 9-4.03 9-9h3l-4.537-4.969z" />
                                                </svg>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </Table>
                    {!settlements && (
                        <div className="settlements-spinner">
                            <Spinner animation="border" />
                        </div>
                    )}
                </div>
                <p className="settlements-help">
                    You need some help? <a href="/">Click here!</a>
                </p>
            </Container>
            <Footer />
        </>
    );
};

export default Dashboard;
