import { api } from "../utils/Common";

export async function getChannels() {
    return (await api.get("/channels")).data.channels;
}

export async function getActiveChannel() {
    return (await api.get("/account/channels/active")).data.data;
}

export async function connectEbay(id) {
    return (await api.get("/account/channels/" + id + "/connect")).data.data;
}

export async function getSettlements() {
    return (await api.get("/account/settlements")).data.data;
}

export function getProfile() {
    return api.get("/account/profile");
}
