import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState, useEffect } from "react";
import { getChannels } from "../api/Account";
import { logout } from "../api/Auth";

const Header = ({ history, profile }) => {
    const [channels, setChannels] = useState([]);

    useEffect(() => {
        async function fetchData() {
            setChannels(await getChannels());
        }
        if (profile !== undefined) fetchData();
    }, [profile]);

    const handleLogout = () => {
        logout().then((res) => {
            if (res.data.success) history.push("/login");
        });
    };

    return (
        <Navbar expand="lg" id="navbar-dashboard" variant="dark">
            <Navbar.Brand href="/">
                <img
                    className="logo"
                    src="/images/logo.svg"
                    alt="SyncBooks Logo"
                    href="https://syncbooks.co.uk/"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="nav-dashboard" />
            <Navbar.Collapse id="nav-dashboard">
                {profile !== undefined && (
                    <>
                        <Nav
                            id="nav-dashboard-main"
                            defaultActiveKey="/settlements"
                        >
                            <Nav.Link href="/settlements">Settlements</Nav.Link>
                            <Nav.Link href="/integrations">
                                Integrations
                            </Nav.Link>
                            <Nav.Link href="/settings">Settings</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <NavDropdown
                                title="a78_htv"
                                id="nav-channel-dropdown"
                                className="channel-dropdown"
                                alignRight
                            >
                                {channels.map(function (channel, index) {
                                    return (
                                        <NavDropdown.Item key={index}>
                                            {channel.name}
                                        </NavDropdown.Item>
                                    );
                                })}
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    onClick={() => history.push("/channels")}
                                >
                                    Add Channel
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown
                                title={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z" />
                                    </svg>
                                }
                                className="navbar-account"
                                alignRight
                            >
                                <NavDropdown.Item>Settings</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleLogout}>
                                    Log Out
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </>
                )}
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
