import Card from "react-bootstrap/Card";
import GoogleLogin from "react-google-login";
import { loginGoogle } from "../api/Auth";

const Login = (props) => {
    const handleLogin = async (google) => {
        const resp = await loginGoogle(google["tokenId"]);
        if (resp.id) {
            props.history.push("/settlements");
        }
    };

    return (
        <>
            <div className="login">
                <div className="login-main">
                    <div className="login-logo">
                        <img
                            src="./images/logo-gray.svg"
                            alt="SyncBooks Logo"
                        />
                    </div>
                    <Card>
                        <Card.Body>
                            <Card.Title>Welcome Back :)</Card.Title>
                            <Card.Text>
                                Simple automation software for synchronizing all
                                your books straight into Xero for an affordable
                                price.
                            </Card.Text>
                            <div className="login-platforms">
                                <ul>
                                    <li>
                                        <GoogleLogin
                                            clientId="543202661649-gd7ctequ24adsei06ovpoveavgcer94o.apps.googleusercontent.com"
                                            onSuccess={handleLogin}
                                            onFailure={handleLogin}
                                            cookiePolicy={"single_host_origin"}
                                            theme="dark"
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div className="login-card-bottom">
                                <Card.Text>
                                    Don't have an account?{" "}
                                    <a href="/register">Click here!</a>
                                </Card.Text>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default Login;
