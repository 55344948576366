import React from "react";

const Footer = () => {
    return (
        <footer>
            <div className="footer-content">
                <span>
                    &copy; {new Date().getFullYear()} SyncBooks | All rights
                    reserved.
                </span>
            </div>
        </footer>
    );
};

export default Footer;
