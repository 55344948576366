import { api } from "../utils/Common";

export async function loginGoogle(token) {
    return (
        await api.post("/auth/google", {
            token,
        })
    ).data;
}

export function logout() {
    return api.get("/auth/logout");
}
