import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

/* Utils */
import PublicRoute from "./utils/PublicRoute";
import PrivateRoute from "./utils/PrivateRoute";

/* Components */
import Login from "./views/Login";
import Settlements from "./views/Settlements/Settlements";
import Channels from "./views/Channels/Channels";

const App = () => {
    return (
        <Router>
            <Switch>
                {/* Public Routes */}
                <PublicRoute path="/login" component={Login} />
                <PublicRoute path="/register" component={Login} />

                {/* Private Routes */}
                <PrivateRoute path="/channels" component={Channels} />
                <PrivateRoute path="/settlements" component={Settlements} />

                {/* Redirect to /login */}
                <Redirect from="*" to="/login" />
            </Switch>
        </Router>
    );
};

export default App;
